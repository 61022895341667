module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"linkImagesToOriginal":false,"maxWidth":650,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Valéria Padilha de Vargas","short_name":"Valéria Padilha de Vargas","start_url":"/","background_color":"#F1C4CB","theme_color":"#E5183A","display":"minimal-ui","icon":"static/assets/icons/maskable_icon.png","icons":[{"src":"static/assets/icons/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x128.png","sizes":"128x128","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"static/assets/icons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5512bb049f0d3fa08630e2e5edccd5d1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
